<template>
  <div id="page-user-list">
      <vs-button color="success" type="filled" to="/blogs/create" icon="add" class="mb-5">Create</vs-button>
      <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
      <!-- AgGrid Table -->
        <ag-grid-vue
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="blogs.data"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="blogs.pagination.per_page"        
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          @filter-changed="onFilterChanged">
        </ag-grid-vue> <!-- End AgGrid Table -->
        <vs-pagination :total="blogs.pagination.last_page" :value="blogs.pagination.current_page" @input="paginate({page: $event})" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'


// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue"
import CellRendererCategory from "./cell-renderer/CellRendererCategory.vue"
import loaderDivMixin from '../../../mixins/loaderDivMixin';

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AgGridVue,
    vSelect,
    CellRendererActions,
    CellRendererCategory,
    CellRendererStatus
  },
  mixins: [loaderDivMixin],
   computed: {
    ...mapGetters({
      blogs: 'blog/getblogs',
      search: 'blog/getSearchObj',
    }),
    searchParams(){
        let params = new URLSearchParams();
        params.append("page", this.page);
        (this.search.query.id != '') ? params.append("query[id]", this.search.query.id) : '';
        (this.search.query.title != '') ? params.append("query[title]", this.search.query.title) : '';
        (this.search.query.blog_category_id != '') ? params.append("query[blog_category_id]", this.search.query.blog_category_id) : '';
        (this.search.query.status != '') ? params.append("query[status]", this.search.query.status) : '';

        return params;
    }
  },
   methods: {
    ...mapActions('blog', [
      'fetchAndSetBlogs',
      'setSearchParameters',
      'clearSearchParameters',
    ]),
    paginate(page){
      this.page=page.page;
      this.fetchBlogs();
    },
    onFilterChanged($event){
      this.setParams($event);
      this.fetchBlogs();
    },
    setParams($event){
      let $this = this;
      const searchKeys = Object.keys($event.api.getFilterModel());
      if(searchKeys.length == 0) this.clearSearchParameters();
      searchKeys.forEach(function(key, val){
        $this.setSearchParameters({key: key, value: $event.api.getFilterModel()[key].filter});
      });
    },
    fetchBlogs(){
      this.openLoading();
      this.fetchAndSetBlogs({query: this.searchParams})
          .then((res) => this.closeLoading())
          .catch((error) => this.closeLoading());
    }
  },
  data() {
    return {

      // AgGrid
      page: 1,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: 'agNumberColumnFilter',
          width: 150,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Title',
          field: 'title',
          filter: true,
          width: 300,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Category',
          field: 'blog_category_id',
          filter: true,
          width: 200,
          cellRendererFramework: 'CellRendererCategory',
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          },
        },
        {
          headerName: 'Status',
          field: 'status',
          // width: 150,
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          // width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererActions,
        CellRendererCategory,
        CellRendererStatus
      }
    }
  },
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
